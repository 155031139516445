import React, {
  useCallback, useEffect, useRef, useContext,
} from 'react';
import { useIntl } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Form from '@nubank/nuds-web/components/Form/Form';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';
import Icon from '@nubank/nuds-web/components/Icon/Icon';
import RadioButtonGroup from '@nubank/nuds-web/components/RadioButtonGroup/RadioButtonGroup';
import PropTypes from 'prop-types';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { css } from 'styled-components';

import {
  getProspectType,
  CREDIT_CARD,
} from '@nubank/www-latam-commons/utils/prospectTypes';
import { SiteContext } from 'components/SiteContext/SiteContext';
import { registerProspect } from 'domains/prospect/registerProspect';
import ColombiaFlag from 'components/ColombiaFlag/ColombiaFlag';
import { documentTypes } from 'utils/documentTypes';
import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import PigIcon from '../Icons/PigIcon';
import { useGetDocumentsByRoute } from '../../../../../hooks/useGetDocuments';
import { LegalDocumentLink } from '../../../../../components/GetLegalDocuments/GetLegalDocuments';

import { StyledCheckbox } from './styles/Checkbox';
import { StyledTypography } from './styles/Typography';
import { StyledLink } from './styles/Link';

const PersonalInfoStep = (
  {
    isLHFActive,
    finalInitialValues,
    setRealtimeResult,
    defaultProps,
  },
) => {
  if (documentTypes.length > 2) {
    documentTypes.pop();
  }
  const DECISION_STEP = 2;
  const PersonaInfoStepLink = StyledLink;
  const { formatMessage } = useIntl();
  const {
    prospectEmail: email,
    startRealtimeAnalysis,
    finishRealtimeAnalysis,
  } = useContext(SiteContext);

  const legalDocuments = useGetDocumentsByRoute();

  const emailInputRef = useRef();

  useEffect(() => {
    sendEvent('PROSPECT_REGISTRATION_ORIGINAL_STARTED');
    emailInputRef.current?.focus();
    emailInputRef.current?.addEventListener('paste', event => event.preventDefault(), false);
  }, []);

  const getLinks = links => {
    const responseLinks = {};

    links?.forEach(link => {
      responseLinks[link.name] = (
        <LegalDocumentLink
          TypeLink={PersonaInfoStepLink}
          linkName={link.name}
          oldPath={formatMessage({ id: link.path })}
          documentsResponse={legalDocuments}
          target="_blank"
          rel="noopener"
          typographyProps={{
            variant: link.variant,
            strong: link.strong,
            color: link.color,
          }}
          intlKey={link.pathLabel}
        />
      );
    });

    return responseLinks;
  };

  const getCompanies = companies => {
    const labels = {};
    companies?.forEach(company => {
      if (company.variant === 'link') {
        labels[company.name] = (
          <LegalDocumentLink
            TypeLink={PersonaInfoStepLink}
            linkName={company.name}
            oldPath={formatMessage({ id: company.path })}
            documentsResponse={legalDocuments}
            target="_blank"
            rel="noopener"
            typographyProps={{
              strong: company.strong,
              color: company.color,
            }}
            intlKey={company.label}
          />
        );
      } else {
        labels[company.name] = (
          <Typography
            tag="span"
            intlKey={company.label}
            strong={company.strong}
          />
        );
      }
    });
    return labels;
  };

  const handleProspectRegistration = useCallback(
    async ({
      nextStep, goToStep, setSubmitting, setFormErrorMsg, values,
    }) => {
      const prospectType = getProspectType(CREDIT_CARD);
      try {
        await registerProspect({
          formValues: values,
          prospectType,
          isLHFActive,
          legalDocuments,
          onApplicationFinish: () => {
            setSubmitting(false);
            goToStep(DECISION_STEP);
          },
          onAnalysisStart() {
            startRealtimeAnalysis();
            setSubmitting(false);
            nextStep();
          },
          onAnalysisFinish(analysisResult) {
            finishRealtimeAnalysis();
            setRealtimeResult(analysisResult);
            goToStep(DECISION_STEP);
          },
        });
      } catch (e) {
        setFormErrorMsg(
          formatMessage({ id: defaultProps.genericErrorMessage.label }),
        );
      } finally {
        setSubmitting(false);
      }
    },
    [legalDocuments],
  );

  return (
    <Form.Step
      initialValues={{
        ...finalInitialValues,
        email,
      }}
      initialTouched={{ email }}
      onSubmit={handleProspectRegistration}
    >
      {({
        isDisabled,
        formErrorMsg,
        isSubmitting,
        clearFormErrorMsg,
        currentStepValues: { documentType },
      }) => {
        const documentIdErrorMessage = new Map([
          ['CC', defaultProps.idNumber.citizenValidationMessage],
          ['CE', defaultProps.idNumber.foreignerValidationMessage],
          ['PPT', defaultProps.idNumber.pptValidationMessage],
        ]);

        return (
          <>
            <Typography
              gutterBottom={1}
              id="personalInfoStepTitle"
              variant="heading4"
              intlKey={defaultProps.title.label}
              css={css`span {color: ${nuDSColor('primary')};} `}
              intlValues={{
                span: msg => (
                  <span>{msg}</span>
                ),
              }}
            />

            {defaultProps.title2 && (
            <Typography
              variant={defaultProps.title2.variant}
              strong={defaultProps.title2.strong}
              color="rgba(0, 0, 0, 0.96)"
              colorVariant="defaultT70"
              intlKey={defaultProps.title2.label}
              intlValues={{
                br: <br />,
              }}
            />
            )}

            <Typography
              gutterBottom={2}
              variant={defaultProps.subtitle.variant}
              strong={defaultProps.subtitle.strong}
              color="black"
              intlKey={defaultProps.subtitle.label}
              css={css`b {font-weight: bold;}`}
              intlValues={{
                br: <br />,
                b: msg => (
                  <b>{msg}</b>
                ),
              }}
            />

            {defaultProps.products && defaultProps.products.map(product => (

              <Box
                backgroundColor={product.backgroundColor}
                borderRadius="8px"
                display="flex"
                height={96}
                marginBottom="1rem"
                key={product.title}
              >
                <Box
                  marginTop="1.5rem"
                  marginLeft="1rem"
                  borderRadius={50}
                  width={50}
                  height={50}
                  backgroundColor={product.iconBackgroundColor}
                  alignItems="center"
                  padding="4x"
                  justifyContent="center"
                >
                  {product.icon === 'card' && (<Icon name="card" color="white" />)}
                  {product.icon === 'pig' && (<PigIcon />)}
                </Box>

                <Box width="15rem" marginLeft="1rem">
                  <Typography
                    variant="subtitle2"
                    strong
                    color={product.titleColor}
                    colorVariant="default"
                    intlKey={product.title}
                    marginTop="1rem"
                    marginBottom="0.1rem"
                  />
                  <Typography
                    variant="paragraph2"
                    intlKey={product.subtitle}
                    marginBottom="0.5rem"
                  />
                </Box>
              </Box>

            ))}

            <br />

            <TextField
              id="email"
              name="email"
              data-testid="email"
              type="email"
              label={formatMessage({ id: defaultProps.email.label })}
              syncValidations={{
                required: formatMessage({ id: defaultProps.email.validationMessage }),
                email: formatMessage({ id: defaultProps.email.validationMessage }),
              }}
            />

            <TextField
              id="confirmEmail"
              name="confirmEmail"
              type="email"
              data-testid="confirm-email"
              ref={emailInputRef}
              tabIndex="0"
              autoComplete="off"
              label={formatMessage({ id: defaultProps.confirmEmail.label })}
              syncValidations={{
                required: formatMessage({ id: defaultProps.confirmEmail.validationMessage }),
                email: formatMessage({ id: defaultProps.confirmEmail.validationMessage }),
                equalsTo: { errorMsg: formatMessage({ id: defaultProps.confirmEmail.differentValidationMessage }), params: { target: 'email' } },
              }}
            />

            {defaultProps.subtitle2 && (
            <Typography
              marginTop="1rem"
              gutterBottom={1}
              variant={defaultProps.subtitle2.variant}
              strong={defaultProps.subtitle2.strong}
              color="black"
              colorVariant="defaultT70"
              intlKey={defaultProps.subtitle2.label}
            />
            )}

            <TextField
              id="names"
              name="names"
              label={formatMessage({ id: defaultProps.names.label })}
              syncValidations={{
                required: formatMessage({ id: defaultProps.names.validationMessage }),
                names: formatMessage({ id: defaultProps.names.validationMessage }),
              }}
            />

            <TextField
              id="firstSurname"
              name="firstSurname"
              label={formatMessage({ id: defaultProps.firstSurname.label })}
              syncValidations={{
                required: formatMessage({ id: defaultProps.firstSurname.validationMessage }),
                firstSurname: formatMessage({ id: defaultProps.firstSurname.validationMessage }),
                nineteenMaxLength: formatMessage({
                  id: defaultProps.firstSurname.maxCharValidationMessage,
                }),
              }}
            />

            <TextField
              id="secondSurname"
              name="secondSurname"
              label={formatMessage({ id: defaultProps.secondSurname.label })}
              syncValidations={{
                secondSurname: formatMessage({ id: defaultProps.secondSurname.validationMessage }),
              }}
            />

            <RadioButtonGroup
              id="documentType"
              name="documentType"
              options={documentTypes}
              label={formatMessage({ id: defaultProps.documentType.label })}
              syncValidations={{
                required: formatMessage({ id: defaultProps.documentType.validationMessage }),
              }}
            />

            <TextField
              id="idNumber"
              name="idNumber"
              label={formatMessage({ id: defaultProps.idNumber.label })}
              syncValidations={{
                idNumber: formatMessage({ id: documentIdErrorMessage.get(documentType) }),
                required: formatMessage({ id: documentIdErrorMessage.get(documentType) }),
              }}
            />

            <Box display="flex" flexDirection="column" width="100%">
              <Typography
                marginBottom="-1rem"
                variant="paragraph2"
                strong
                intlKey={defaultProps.phoneNumber.label}
                color="black.defaultT70"
              />
              <Box display="flex">
                <Box
                  display="flex"
                  whiteSpace="nowrap"
                  width="76px"
                  height="32px"
                  marginTop="1.25rem"
                  backgroundColor="white.dark"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius=".5rem"
                  marginRight="2x"
                  padding="1x"
                >
                  <ColombiaFlag />
                  <Typography
                    variant="paragraph1"
                    strong
                    color="black.defaultT70"
                  >
                    +57
                  </Typography>
                </Box>
                <Box width="100%">
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    label=""
                    placeholder={formatMessage({ id: defaultProps.phoneNumber.label })}
                    maxLength={10}
                    syncValidations={{
                      required: formatMessage({
                        id: defaultProps.phoneNumber.validationMessage,
                      }),
                      phoneNumber: formatMessage({
                        id: defaultProps.phoneNumber.validationMessage,
                      }),
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <StyledCheckbox
              id="policyAccepted"
              data-testid="policyAccepted"
              name="policyAccepted"
              syncValidations={{
                required: formatMessage({
                  id: defaultProps.privacyPolicy.validationMessage,
                }),
              }}
              label={(
                <Typography
                  variant={defaultProps.privacyPolicy.variant}
                  strong={defaultProps.privacyPolicy.strong}
                  tag="span"
                  intlKey={defaultProps.privacyPolicy.label}
                  intlValues={getLinks(defaultProps.privacyPolicy.links)}
                />
              )}
            />

            {defaultProps.termsConditions && (
            <StyledCheckbox
              id="termsConditionsAccepted"
              data-testid="termsConditionsAccepted"
              name="termsConditionsAccepted"
              syncValidations={{
                required: formatMessage({
                  id: defaultProps.termsConditions.validationMessage,
                }),
              }}
              label={(
                <Typography
                  variant={defaultProps.termsConditions.variant}
                  strong={defaultProps.termsConditions.strong}
                  tag="span"
                  intlKey={defaultProps.termsConditions.label}
                  intlValues={getLinks(defaultProps.termsConditions.links)}
                />
              )}
            />
            )}

            <StyledCheckbox
              id="whatsappAccepted"
              data-testid="whatsappAccepted"
              name="whatsappAccepted"
              label={(
                <Typography
                  variant={defaultProps.whatsappMessage.variant}
                  strong={defaultProps.whatsappMessage.strong}
                  tag="span"
                  intlKey={defaultProps.whatsappMessage.label}
                  intlValues={defaultProps.whatsappMessage.companies
                    ? getCompanies(defaultProps.whatsappMessage.companies)
                    : {
                      WhatsApp: (
                        <StyledTypography
                          variant={defaultProps.whatsappMessage.variant}
                          color="black"
                          strong={defaultProps.whatsappMessage.strong}
                          tag="span"
                          intlKey={defaultProps.whatsappMessage.company}
                        />
                      ),
                    }}
                />
              )}
            />

            <Button
              id="personalinfo-step-submit-btn"
              intlKey={defaultProps.submitButton.label}
              type="submit"
              variant="contained"
              extended
              disabled={isDisabled || isSubmitting}
              iconProps={defaultProps.submitButton.iconProps}
            />

            <Snackbar
              visible={Boolean(formErrorMsg)}
              onActionClick={clearFormErrorMsg}
              actionText={formatMessage({ id: defaultProps.snackBar.label })}
            >
              {formErrorMsg}
            </Snackbar>
          </>
        );
      }}
    </Form.Step>
  );
};

PersonalInfoStep.defaultProps = {
  isLHFActive: false,
  setRealtimeResult: () => {},
};

PersonalInfoStep.propTypes = {
  defaultProps: PropTypes.shape({
    confirmEmail: PropTypes.shape({
      differentValidationMessage: PropTypes.string,
      label: PropTypes.string,
      validationMessage: PropTypes.string,
    }),
    documentType: PropTypes.shape({
      label: PropTypes.string,
      validationMessage: PropTypes.string,
    }),
    email: PropTypes.shape({
      label: PropTypes.string,
      validationMessage: PropTypes.string,
    }),
    firstSurname: PropTypes.shape({
      label: PropTypes.string,
      maxCharValidationMessage: PropTypes.string,
      validationMessage: PropTypes.string,
    }),
    genericErrorMessage: PropTypes.shape({
      label: PropTypes.string,
    }),
    idNumber: PropTypes.shape({
      citizenValidationMessage: PropTypes.string,
      foreignerValidationMessage: PropTypes.string,
      label: PropTypes.string,
      pptValidationMessage: PropTypes.string,
    }),
    names: PropTypes.shape({
      label: PropTypes.string,
      validationMessage: PropTypes.string,
    }),
    phoneNumber: PropTypes.shape({
      label: PropTypes.string,
      validationMessage: PropTypes.string,
    }),
    privacyPolicy: PropTypes.shape({
      label: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        pathLabel: PropTypes.string,
        strong: PropTypes.bool,
        variant: PropTypes.string,
      })),
      strong: PropTypes.bool,
      validationMessage: PropTypes.string,
      variant: PropTypes.string,
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
      backgroundColor: PropTypes.string,
      icon: PropTypes.string,
      iconBackgroundColor: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
      titleColor: PropTypes.string,
    })),
    secondSurname: PropTypes.shape({
      label: PropTypes.string,
      validationMessage: PropTypes.string,
    }),
    snackBar: PropTypes.shape({
      label: PropTypes.string,
    }),
    submitButton: PropTypes.shape({
      iconProps: PropTypes.shape({
        name: PropTypes.string,
      }),
      label: PropTypes.string,
    }),
    subtitle: PropTypes.shape({
      label: PropTypes.string,
      strong: PropTypes.bool,
      variant: PropTypes.string,
    }),
    subtitle2: PropTypes.shape({
      label: PropTypes.string,
      strong: PropTypes.bool,
      variant: PropTypes.string,
    }),
    termsConditions: PropTypes.shape({
      label: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
        pathLabel: PropTypes.string,
        strong: PropTypes.bool,
        variant: PropTypes.string,
      })),
      strong: PropTypes.bool,
      validationMessage: PropTypes.string,
      variant: PropTypes.string,
    }),
    title: PropTypes.shape({
      label: PropTypes.string,
    }),
    title2: PropTypes.shape({
      label: PropTypes.string,
      strong: PropTypes.bool,
      variant: PropTypes.string,
    }),
    whatsappMessage: PropTypes.shape({
      companies: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        strong: PropTypes.bool,
        variant: PropTypes.string,
      })),
      company: PropTypes.string,
      label: PropTypes.string,
      strong: PropTypes.bool,
      variant: PropTypes.string,
    }),
  }).isRequired,
  finalInitialValues: PropTypes.shape({
    confirmEmail: PropTypes.string,
    documentType: PropTypes.string,
    firstSurname: PropTypes.string,
    idNumber: PropTypes.string,
    names: PropTypes.string,
    phoneNumber: PropTypes.string,
    policyAccepted: PropTypes.bool,
    secondSurname: PropTypes.string,
    whatsappAccepted: PropTypes.bool,
  }).isRequired,
  isLHFActive: PropTypes.bool,
  setRealtimeResult: PropTypes.func,
};

export default PersonalInfoStep;
