import { sanitizeAndValidateRegex } from '@nubank/www-latam-commons/utils/form/validationUtils';

const CC_FORMAT_REGEX = /^((?!(0))\d{4,12})$/;
const CE_FORMAT_REGEX = /^((?!(0))\d{4,10})$/;
const PPT_FORMAT_REGEX = /^((?!(0))\d{7,7})$/;
const STARTS_WITH_THREE_AND_HAS_10_DIGITS = /^3\d{9}$/;
const NOT_A_DIGIT = /\D/g;
const NINETEEN_MAX_CHARS = /^.{0,19}$/;

function validateID(str, regex) {
  return Boolean(str) && sanitizeAndValidateRegex(str, regex);
}

function validateCEId(str, regex) {
  return (
    validateID(str, regex)
    && Number(str) > 1000
  );
}

const documentIDValidator = new Map([
  ['CC', documentID => validateID(documentID, CC_FORMAT_REGEX)],
  ['CE', documentID => validateCEId(documentID, CE_FORMAT_REGEX)],
  ['PPT', documentID => validateID(documentID, PPT_FORMAT_REGEX)],
]);

export function validateDocumentID(str, _, { documentType }) {
  const validator = documentIDValidator.get(documentType);
  return validator(str);
}

export function validatePhone(str) {
  const numberWithoutSpace = str.replace(NOT_A_DIGIT, '');
  return sanitizeAndValidateRegex(numberWithoutSpace, STARTS_WITH_THREE_AND_HAS_10_DIGITS);
}

export function hasLessThanTwentyChars(str) {
  return Boolean(str) && sanitizeAndValidateRegex(str, NINETEEN_MAX_CHARS);
}
